'use client'

import React, { useState, useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Swal from 'sweetalert2'
import {Card, Table, Button, Form, InputGroup, Modal, Row, Col, Alert, Dropdown, DropdownButton } from 'react-bootstrap'
import { User, Phone, Mail, MapPin, Droplet, Search, Trash, Plus, FileText, Printer, UserPlus, Calendar } from 'lucide-react'

import { fetchPatients, addPatient, fetchMedicines, addPrescription, fetchPrescription, updatePatientVitals } from '../../services/AxiosInstance'
import PrescriptionReceipt from './PrescriptionReceipt'
import PaginationComponent from './PaginationComponent'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function PatientManagement() {
  const [patients, setPatients] = useState([])
  const [medicines, setMedicines] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showPrescribeModal, setShowPrescribeModal] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [newPatient, setNewPatient] = useState({
    name: '',
    age: '',
    gender: '',
    contact: '',
    email: '',
    address: '',
    diseases: '',
    bloodGroup: '',
  })
  const [prescription, setPrescription] = useState({
    patientId: null,
    medicines: [{ medicineId: '', dosage: '', duration: '' }],
    advice: '',
    pulse: '',
    bloodPressure: '',
    weight: '',
    followUpDate: '',
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [selectedPrescription, setSelectedPrescription] = useState(null)
  const prescriptionRef = useRef(null)

  const [patientsPerPage, setPatientsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const [showAllPrescriptionsModal, setShowAllPrescriptionsModal] = useState(false)
  const [allPrescriptions, setAllPrescriptions] = useState([])

  useEffect(() => {
    loadPatients()
    loadMedicines()
  }, [])

  const loadPatients = async () => {
    try {
      setLoading(true)
      const data = await fetchPatients()
      // Sort patients in descending order by ID
      const sortedPatients = data.sort((a, b) => b.id - a.id)
      setPatients(sortedPatients)
      setError(null)
    } catch (err) {
      setError('Failed to load patients. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const loadMedicines = async () => {
    try {
      const data = await fetchMedicines()
      setMedicines(data)
    } catch (err) {
      setError('Failed to load medicines. Please try again.')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewPatient({ ...newPatient, [name]: value })
  }

  const handlePrescriptionChange = (index, field, value) => {
    const updatedMedicines = [...prescription.medicines]
    updatedMedicines[index][field] = value
    setPrescription({ ...prescription, medicines: updatedMedicines })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const addedPatient = await addPatient(newPatient)
      setPatients([...patients, addedPatient])
      setShowModal(false)
      setNewPatient({
        name: '',
        age: '',
        gender: '',
        contact: '',
        email: '',
        address: '',
        diseases: '',
        bloodGroup: '',
      })
      
      Swal.fire({
        title: 'Patient Added Successfully',
        text: 'Do you want to prescribe medicine for this patient?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Prescribe Medicine',
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          handlePrescribe(addedPatient)
        }
      })
    } catch (err) {
      setError('Failed to add patient. Please try again.')
    }
  }

  const handlePrescribe = (patient) => {
    setSelectedPatient(patient)
    setPrescription(prevPrescription => ({
      ...prevPrescription,
      patientId: patient.id,
      pulse: patient.pulse || '',
      bloodPressure: patient.bloodPressure || '',
      weight: patient.weight || '',
    }))
    setShowPrescribeModal(true)
  }

  const handleAddMedicine = () => {
    setPrescription({
      ...prescription,
      medicines: [...prescription.medicines, { medicineId: '', dosage: '', duration: '' }],
    })
  }

  const handleRemoveMedicine = (index) => {
    const updatedMedicines = prescription.medicines.filter((_, i) => i !== index)
    setPrescription({ ...prescription, medicines: updatedMedicines })
  }

  const handlePrescriptionSubmit = async (e) => {
    e.preventDefault()
    
    if (!selectedPatient) {
      setError('No patient selected. Please try again.')
      return
    }
  
    const prescriptionToSubmit = {
      patientId: selectedPatient.id,
      prescriptionDate: new Date().toISOString().split('T')[0],
      advice: prescription.advice,
      followUpDate: prescription.followUpDate,
      medicines: prescription.medicines.map(med => ({
        medicineId: med.medicineId,
        dosage: med.dosage,
        duration: med.duration,
      })),
      pulse: prescription.pulse,
      bloodPressure: prescription.bloodPressure,
      weight: prescription.weight,
    }
  
    try {
      const addedPrescription = await addPrescription(prescriptionToSubmit)
      
      const vitals = {
        pulse: prescription.pulse,
        bloodPressure: prescription.bloodPressure,
        weight: prescription.weight,
      }
  
      await updatePatientVitals(selectedPatient.id, vitals)
      
      
       // Create updated patient object with new vitals
      const updatedPatient = {
        ...selectedPatient,
        ...vitals,
        lastVisit: new Date().toISOString().split('T')[0],
      };

      setPatients(prevPatients => 
        prevPatients.map(patient => 
          patient.id === selectedPatient.id 
            ? { ...patient, ...vitals, lastVisit: new Date().toISOString().split('T')[0] } 
            : patient
        )
      )

      setShowPrescribeModal(false)
      setPrescription({
        patientId: null,
        medicines: [{ medicineId: '', dosage: '', duration: '' }],
        advice: '',
        followUpDate: '',
        pulse: '',
        bloodPressure: '',
        weight: '',
      })
      

      Swal.fire({
        title: 'Prescription Submitted',
        text: 'Your prescription has been successfully submitted.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Print Prescription',
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          handlePrintPrescription(updatedPatient, addedPrescription)
        }
      })

      setSelectedPatient(null)
    } catch (err) {
      console.error(err)
      setError('Failed to add prescription or update patient vitals. Please try again.')
    }
  }

  const handlePrintPrescription = async (patient, prescription) => {
    setSelectedPatient(patient)
    setSelectedPrescription(prescription)

    setTimeout(() => {
      handlePrint()
    }, 100)
  }

  const handlePrint = useReactToPrint({
    content: () => prescriptionRef.current,
    onAfterPrint: () => {
      setSelectedPatient(null)
      setSelectedPrescription(null)
    },
  })

  const handleAllPrintPrescription = async (patient) => {
    try {
      const prescriptions = await fetchPrescription(patient.id)
      setSelectedPatient(patient)
      setAllPrescriptions(prescriptions)
      setShowAllPrescriptionsModal(true)
    } catch (err) {
      setError('Failed to fetch prescriptions. Please try again.')
    }
  }

  const handlePrintIndividualPrescription = (prescription) => {
    setSelectedPrescription(prescription)
    setTimeout(() => {
      handlePrint()
    }, 100)
  }

  const filteredPatients = patients.filter(patient =>
    patient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.contact.includes(searchTerm) ||
    patient.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.address.toLowerCase().includes(searchTerm.toLowerCase()) 
  )

  const indexOfLastPatient = currentPage * patientsPerPage
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const handlePatientsPerPageChange = (e) => {
    setPatientsPerPage(Number(e))
    setCurrentPage(1)
  }

  if (loading) {
    return <div>Loading patients...</div>
  }

  return (
    <div className="container-fluid mx-auto">
      <h1 className="mb-6 fs-4 text-left" style={{fontFamily:'algerian'}}>Patient Management</h1>
      {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
     

      <div className="mb-4 mt-4">
      <div className="row g-3">
        <div className="col-12 col-md-6 col-lg-8">
          <div className="d-flex flex-wrap gap-2">
            <DropdownButton 
              variant='secondary'
              id="dropdown-basic-button"
              title={`Show ${patientsPerPage} Records`}
              onSelect={handlePatientsPerPageChange}
            >
              <Dropdown.Item eventKey="10">10</Dropdown.Item>
              <Dropdown.Item eventKey="20">20</Dropdown.Item>
              <Dropdown.Item eventKey="50">50</Dropdown.Item>
            </DropdownButton>

            <Button 
              onClick={() => setShowModal(true)} 
              className="flex-grow-1 flex-md-grow-0" 
              style={{backgroundColor:'#36c95f'}}
            >
              <UserPlus className="me-2" size={20}/>
              Add New Patient
            </Button>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <InputGroup>
            <InputGroup.Text>
              <Search className="h-5 w-5" />
            </InputGroup.Text>
            <Form.Control 
              placeholder="Search patients..." 
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
              className="border-gray-300 focus:border-blue-500 focus:ring-#36c95f-500"
            />         
          </InputGroup>
        </div>
      </div>
    </div>
    

      <Table striped hover responsive className="shadow-md" style={{borderRadius:'12px', textWrap:'nowrap'}}>
        <thead className="table-white p-2">
          <tr>
            <th className="px-3 py-3">#</th>
            <th className="px-3 py-3">Actions</th>
            <th className="px-3 py-3">Name</th>
            <th className="px-3 py-3">Age</th>
            <th className="px-3 py-3">Gender</th>
            <th className="px-3 py-3">Contact</th>
            <th className="px-3 py-3">Blood Group</th> 
            <th className="px-3 py-3">Pulse</th> 
            <th className="px-3 py-3">Blood Pressure</th> 
            <th className="px-3 py-3">Weight (kg)</th> 
            <th className="px-3 py-3">Diseases</th>
            <th className="px-3 py-3">Last Visit</th>
            <th className="px-3 py-3">Email</th>
            <th className="px-3 py-3">Address</th>         
          </tr>
        </thead>
        <tbody style={{fontSize:'14px'}}>
          {currentPatients.map((patient) => (
            <tr key={patient.id}>
              <td className="px-1 py-1">{patient.id}</td>
              <td className="px-1">
                <Trash className="h-4 w-4 text-danger me-2" title='Delete' style={{cursor:'pointer'}}/>
                <FileText className="h-4 w-4 text-success me-2" title='Prescribe Medicines' onClick={() => handlePrescribe(patient)} style={{cursor:'pointer'}}/>
                <Printer className="h-4 w-4 text-info" title='Prescription Receipt' onClick={() => handleAllPrintPrescription(patient)} style={{cursor:'pointer'}}/>
              </td>
              <td className="px-1 py-1 user-select-all">{patient.name}</td>
              <td className="px-1 py-1 user-select-all">{patient.age}</td>
              <td className="px-1 py-1 user-select-all">{patient.gender}</td>
              <td className="px-1 py-1 user-select-all">{patient.contact}</td>
              <td className="px-1 py-1 user-select-all">{patient.bloodGroup}</td> 
              <td className="px-1 py-1 user-select-all">{patient.pulse}</td>
              <td className="px-1 py-1 user-select-all">{patient.bloodPressure}</td>
              <td className="px-1 py-1 user-select-all">{patient.weight}</td>
              <td className="px-1 py-1 user-select-all">{patient.diseases}</td>
              <td className="px-1 py-1 user-select-all">{patient.lastVisit || '-'}</td>
              <td className="px-1 py-1 user-select-all">{patient.email}</td>
              <td className="px-1 py-1 user-select-all">{patient.address}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className='d-flex align-items-baseline justify-content-between'>
        <div className='float-start'>
          <span> 
            Showing {indexOfFirstPatient + 1} to{' '}
            {indexOfLastPatient > filteredPatients.length
              ? filteredPatients.length
              : indexOfLastPatient}{' '}
            of {filteredPatients.length} entries
          </span>
        </div>
        <div className='float-end'>
          <PaginationComponent
            currentPage={currentPage}
            patientsPerPage={patientsPerPage}
            totalPatients={filteredPatients.length}
            paginate={paginate}
          />
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" backdrop="static" keyboard={false}>
      <Modal.Header closeButton className="bg-primary text-white">
        <Modal.Title>
          <UserPlus size={24} className="me-2" />
          Add New Patient
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-info text-white">
              <h5 className="mb-0">Personal Information</h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <User size={18} className="me-2" />
                      Full Name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={newPatient.name}
                      onChange={handleInputChange}
                      placeholder="John Doe"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Age*</Form.Label>
                    <Form.Control
                      type="number"
                      name="age"
                      value={newPatient.age}
                      onChange={handleInputChange}
                      placeholder="30"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      name="gender"
                      value={newPatient.gender}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <Phone size={18} className="me-2" />
                      Contact
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      name="contact"
                      value={newPatient.contact}
                      onChange={handleInputChange}
                      placeholder="+1 (555) 987-6543"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <Mail size={18} className="me-2" />
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={newPatient.email}
                      onChange={handleInputChange}
                      placeholder="john@example.com"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-4 shadow-sm">
            <Card.Header className="bg-success text-white">
              <h5 className="mb-0">Additional Details</h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <MapPin size={18} className="me-2" />
                      Address
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="address"
                      value={newPatient.address}
                      onChange={handleInputChange}
                      placeholder="123 Main St, City, Country"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <Droplet size={18} className="me-2" />
                      Blood Group
                    </Form.Label>
                    <Form.Select
                      name="bloodGroup"
                      value={newPatient.bloodGroup}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <FileText size={18} className="me-2" />
                      Known Diseases*
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="diseases"
                      value={newPatient.diseases}
                      onChange={handleInputChange}
                      placeholder="List any known diseases or conditions"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={() => setShowModal(false)} className="me-2">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              <UserPlus size={18} className="me-2" />
              Add Patient
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>

      <Modal show={showPrescribeModal} size="lg" onHide={() => setShowPrescribeModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Prescription for {selectedPatient?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form onSubmit={handlePrescriptionSubmit}>
            <Card className="mb-4 shadow-sm">
              <Card.Header className="bg-info text-white">
                <h5 className="mb-0">Vital Signs</h5>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Pulse</Form.Label>
                      <InputGroup>
                        <InputGroup.Text><i className="fas fa-heartbeat"></i></InputGroup.Text>
                        <Form.Control
                          type="number"
                          step="1"
                          placeholder="Enter pulse"
                          value={prescription.pulse || ''}
                          onChange={(e) => setPrescription({ ...prescription, pulse: e.target.value })}
                        />
                        <InputGroup.Text>bpm</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Blood Pressure</Form.Label>
                      <InputGroup>
                        <InputGroup.Text><i className="fas fa-tint"></i></InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="e.g., 120/80"
                          value={prescription.bloodPressure || ''}
                          onChange={(e) => setPrescription({ ...prescription, bloodPressure: e.target.value })}
                        />
                        <InputGroup.Text>mmHg</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Weight</Form.Label>
                      <InputGroup>
                        <InputGroup.Text><i className="fas fa-weight"></i></InputGroup.Text>
                        <Form.Control
                          type="number"
                          step="0.1"
                          placeholder="Enter weight"
                          value={prescription.weight || ''}
                          onChange={(e) => setPrescription({ ...prescription, weight: e.target.value })}
                        />
                        <InputGroup.Text>kg</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-4 shadow-sm">
              <Card.Header className="bg-success text-white">
                <h5 className="mb-0">Prescribed Medicines</h5>
              </Card.Header>
              <Card.Body>
                {prescription.medicines.map((medicine, index) => (
                  <Row key={index} className="mb-3 align-items-end">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Medicine</Form.Label>
                        <Form.Select
                          value={medicine.medicineId}
                          onChange={(e) => handlePrescriptionChange(index, 'medicineId', e.target.value)}
                        >
                          <option value="">Select Medicine</option>
                          {medicines.map((med) => (
                            <option key={med.id} value={med.id}>{med.name}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Dosage</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="e.g., 1 tablet"
                          value={medicine.dosage}
                          onChange={(e) => handlePrescriptionChange(index, 'dosage', e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="e.g., 7 days"
                          value={medicine.duration}
                          onChange={(e) => handlePrescriptionChange(index, 'duration', e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Button variant="outline-danger" onClick={() => handleRemoveMedicine(index)}>
                        <Trash size={18} />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button variant="outline-success" onClick={handleAddMedicine} className="mt-2">
                  <Plus size={18} /> Add Medicine
                </Button>
              </Card.Body>
            </Card>

            <Card className="mb-4 shadow-sm">
              <Card.Header className="bg-warning">
                <h5 className="mb-0">Additional Information</h5>
              </Card.Header>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Advice</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={prescription.advice}
                    onChange={(e) => setPrescription({ ...prescription, advice: e.target.value })}
                    placeholder="Enter any additional advice for the patient"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Follow-up Date</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <Calendar size={18} />
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      value={prescription.followUpDate}
                      onChange={(e) => setPrescription({ ...prescription, followUpDate: e.target.value })}
                    />
                  </InputGroup>
                </Form.Group>
              </Card.Body>
            </Card>

            <div className="d-grid">
              <Button variant="primary" size="lg" type="submit">
                Submit Prescription
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal 
        show={showAllPrescriptionsModal} 
        onHide={() => setShowAllPrescriptionsModal(false)} 
        size="xl" 
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>
            <h4 className="mb-0">Prescription History for {selectedPatient?.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          {allPrescriptions.length === 0 ? (
            <Alert variant="info">No prescriptions found for this patient.</Alert>
          ) : (
            <div className="prescription-list">
              {allPrescriptions.map((prescription, index) => (
                <Card key={prescription.id} className="mb-4 shadow-sm">
                  <Card.Header className="bg-info text-white d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Prescription {index + 1}</h5>
                    <small>{new Date(prescription.prescriptionDate).toLocaleDateString()}</small>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={4}>
                        <h6 className="text-muted mb-2">Vital Signs</h6>
                        <ul className="list-unstyled">
                          <li><strong>Pulse:</strong> {prescription.pulse} bpm</li>
                          <li><strong>Blood Pressure:</strong> {prescription.bloodPressure} mmHg</li>
                          <li><strong>Weight:</strong> {prescription.weight} kg</li>
                        </ul>
                      </Col>
                      <Col md={8}>
                        <h6 className="text-muted mb-2">Prescribed Medicines</h6>
                        {prescription.prescribedMedicines && prescription.prescribedMedicines.length > 0 ? (
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Medicine</th>
                                <th>Dosage</th>
                                <th>Duration</th>
                              </tr>
                            </thead>
                            <tbody>
                              {prescription.prescribedMedicines.map((medicine, medIndex) => (
                                <tr key={medIndex}>
                                  <td>{medicine.medicineName}</td>
                                  <td>{medicine.dosage}</td>
                                  <td>{medicine.duration}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <p className="text-muted">No medicines listed for this prescription.</p>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <h6 className="text-muted mb-2">Advice</h6>
                        <p>{prescription.advice || 'No advice provided.'}</p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <h6 className="text-muted mb-2">Follow-up Date</h6>
                        <p>{prescription.followUpDate ? new Date(prescription.followUpDate).toLocaleDateString() : 'No follow-up date set.'}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="bg-light">
                    <Button 
                      variant="outline-primary" 
                      size="sm"
                      onClick={() => handlePrintIndividualPrescription(prescription)}
                    >
                      <Printer size={16} className="me-2" />
                      Print This Prescription
                    </Button>
                  </Card.Footer>
                </Card>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAllPrescriptionsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ display: 'none' }}>
        {selectedPrescription && selectedPatient && (
          <div ref={prescriptionRef}>
            <PrescriptionReceipt prescription={selectedPrescription} patient={selectedPatient} />
          </div>
        )}
      </div>
    </div>
  )
}