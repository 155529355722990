import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { fetchTotalPatients, fetchNewPatients, fetchTotalMedicines } from '../services/AxiosInstance'; 

export default function Analytics() {
  const [totalPatients, setTotalPatients] = useState(0);
  const [newPatients, setNewPatients] = useState(0);
  const [totalMedicines, setTotalMedicines] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0); // State for total revenue

  useEffect(() => {
    const getCounts = async () => {
      try {
        const totalPatientsCount = await fetchTotalPatients();
        const newPatientsCount = await fetchNewPatients();
        const totalMedicinesCount = await fetchTotalMedicines();

        setTotalPatients(totalPatientsCount);
        setNewPatients(newPatientsCount);
        setTotalMedicines(totalMedicinesCount);

        // Calculate total revenue based on total patients
        const revenue = totalPatientsCount * 200; // Assuming 200 is the revenue per patient
        setTotalRevenue(revenue);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    getCounts();
  }, []);

  return (
    <div>
      <h1 className="mb-4" style={{ fontFamily: 'algerian' }}>Clinic Dashboard</h1>
      <Row>
        <Col md={3}>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Javanese Text' }}>Total Patients</Card.Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="blue"
                strokeWidth={2}
                style={{ width: '40px', height: '40px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 11c1.104 0 2-.896 2-2s-.896-2-2-2-2 .896-2 2 .896 2 2 2zm-8 0c1.104 0 2-.896 2-2s-.896-2-2-2-2 .896-2 2 .896 2 2 2zm8 2c-1.657 0-3 1.343-3 3v1h6v-1c0-1.657-1.343-3-3-3zm-8 0c-1.657 0-3 1.343-3 3v1h6v-1c0-1.657-1.343-3-3-3zm8-4c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm-8 0c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm8 6c-2.21 0-4 1.79-4 4h8c0-2.21-1.79-4-4-4zm-8 0c-2.21 0-4 1.79-4 4h8c0-2.21-1.79-4-4-4z"
                />
              </svg>
              <Card.Text className="h2">{totalPatients}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Javanese Text' }}>New Patients</Card.Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="purple"
                strokeWidth={2}
                style={{ width: '40px', height: '40px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 14c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm0 2c-2.21 0-4 1.79-4 4v2h8v-2c0-2.21-1.79-4-4-4zm10-4v-2m-2 1h4M6 6v2m2-2H4"
                />
              </svg>
              <Card.Text className="h2">{newPatients}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Javanese Text' }}>Total Medicines</Card.Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                style={{ width: '40px', height: '40px' }}
              >
                <path
                  d="M19 5a5 5 0 0 0-7.07 0L5 11.93a5 5 0 0 0 7.07 7.07L19 12.07A5 5 0 0 0 19 5z"
                  fill="#34D399"
                />
                <path
                  d="M12 12l5.07-5.07A5 5 0 0 0 7 17.07L12 12z"
                  fill="#6EE7B7"
                />
                <circle
                  cx="7"
                  cy="17"
                  r="4"
                  fill="#60A5FA"
                />
              </svg>
              <Card.Text className="h2">{totalMedicines}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title style={{ fontFamily: 'Javanese Text' }}>Total Revenue</Card.Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="green"
                strokeWidth={2}
                style={{ width: '40px', height: '40px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 1v22m6-16h-4a2 2 0 0 0 0 4h2a2 2 0 1 1 0 4h-4m0-4H6"
                />
              </svg>
              <Card.Text className="h2">₹{totalRevenue.toLocaleString()}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
