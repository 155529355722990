import React, { useState, useEffect } from 'react'
import { Table, Button, Form, InputGroup, Modal, Alert, Pagination } from 'react-bootstrap'
import { Search, Edit, Trash, Plus } from 'lucide-react'
import { fetchMedicines, addMedicine } from '../../services/AxiosInstance'

export default function MedicineManagement() {
  const [medicines, setMedicines] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [newMedicine, setNewMedicine] = useState({
    name: '',
    category: '',
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [medicinesPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    loadMedicines()
  }, [])

  const loadMedicines = async () => {
    try {
      setLoading(true)
      const data = await fetchMedicines()
      setMedicines(data)
      setError(null)
    } catch (err) {
      setError('Failed to load medicines. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewMedicine({ ...newMedicine, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const addedMedicine = await addMedicine(newMedicine)
      setMedicines([...medicines, addedMedicine])
      setShowModal(false)
      setNewMedicine({
        name: '',
        category: '',
      })
    } catch (err) {
      setError('Failed to add medicine. Please try again.')
    }
  }

  // Search and pagination logic
  const filteredMedicines = medicines.filter(medicine =>
    medicine.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    medicine.category.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const indexOfLastMedicine = currentPage * medicinesPerPage
  const indexOfFirstMedicine = indexOfLastMedicine - medicinesPerPage
  const currentMedicines = filteredMedicines.slice(indexOfFirstMedicine, indexOfLastMedicine)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  if (loading) {
    return <div>Loading medicines...</div>
  }

  return (
    <div>
      <h1 className="mb-4" style={{fontFamily:'algerian'}}>Medicine Management</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <InputGroup className="mb-3">
        <InputGroup.Text>
          <Search size={18} />
        </InputGroup.Text>
        <Form.Control 
          placeholder="Search medicines..." 
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setCurrentPage(1)
          }}
        />
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <Plus size={18} className="me-2" />
          Add New Medicine
        </Button>
      </InputGroup>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Actions</th>
            <th>ID</th>
            <th>Name</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {currentMedicines.map((medicine) => (
            <tr key={medicine.id}>
              <td>
                <Button variant="primary rounded-pill" size="sm" className="me-2">
                  <Edit size={18} />
                </Button>
                <Button variant="danger rounded-pill" size="sm">
                  <Trash size={18} />
                </Button>
              </td>
              <td>{medicine.id}</td>
              <td>{medicine.name}</td>
              <td>{medicine.category}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="justify-content-center">
        {[...Array(Math.ceil(filteredMedicines.length / medicinesPerPage)).keys()].map((number) => (
          <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Medicine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newMedicine.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={newMedicine.category}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Medicine
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}