import { useState } from 'react';
import { ShieldPlusIcon } from "lucide-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loginbg from '../images/login-bg.png';
import './Sign-in.css';
import { login } from '../services/AxiosInstance';  // Import the login API

export default function Component() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberPreference, setRememberPreference] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const credentials = { email, password };
      const data = await login(credentials); // Call login API
      console.log('Login successful:', data);

      // Store token if required (based on API response)
      if (data.jwtToken) {
        localStorage.setItem('jwtToken', data.jwtToken);
      }
        
      window.location.href = '/dashboard';

    } catch (error) {
      setErrorMessage('Login failed. Please check your credentials.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="row g-0 vh-100 bg-white d-flex flex-column mt-0 pt-0" style={{ overflow:'hidden' }}>
      {/* Form Column */}
      <div className="col-md-6 shadow d-flex flex-column justify-content-center p-5 pt-0 h-100" style={{ marginLeft: '60px' }}>
        <div className="mb-1 text-center">
          <div className="d-flex justify-content-center align-items-center text-success mb-3">
            <ShieldPlusIcon style={{width:'20%',height:'20%'}}/>
          </div>
          <h2 className="font-weight-bold">Welcome to OM Clinic</h2>
          <p className="text-muted">Sign in by entering information below</p>
        </div>

        <form onSubmit={handleSubmit} className="mt-3 ">
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="demo@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="remember"
              checked={rememberPreference}
              onChange={(e) => setRememberPreference(e.target.checked)}
            />
            <label htmlFor="remember" className="form-check-label">
              Remember my preference
            </label>
          </div>
          <button type="submit" className="btn btn-primary w-100">Sign In</button>
        </form>
      </div>

      {/* Image Column */}
      <div className="col-md-6 d-none d-md-block p-0">
        <div className=" h-100" style={{ transform: 'skew(-5deg)', width:'120%', marginLeft:'-30px' }}>
          <img src={Loginbg} alt="Login Background" className="img-fluid w-100 h-100" style={{ objectFit: 'cover' }} />
        </div>
      </div>
    </div>
  );
}
