import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure this is set in your .env file
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// Request Interceptor to add Authorization token
axiosInstance.interceptors.request.use(
    (config) => {
        const jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) {
            config.headers['Authorization'] = `Bearer ${jwtToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Fetch all patients
export const fetchPatients = async () => {
  try {
    const response = await axiosInstance.get('/backend/patients');
    return response.data;
  } catch (error) {
    console.error('Error fetching patients:', error);
    throw error;
  }
};

// Add a new patient
export const addPatient = async (patientData) => {
  try {
    const response = await axiosInstance.post('/backend/patients', patientData);
    return response.data;
  } catch (error) {
    console.error('Error adding patient:', error);
    throw error;
  }
};

// Add a new patient
export const updatePatientVitals = async (patientId, vitals) => {
  try {
    const response = await axiosInstance.put(`/backend/patients/${patientId}`, vitals );
    return response.data;
  } catch (error) {
    console.error('Error updating patient:', error);
    throw error;
  }
};

// Fetch all medicines
export const fetchMedicines = async () => {
  try {
    const response = await axiosInstance.get('/backend/medicines');
    return response.data;
  } catch (error) {
    console.error('Error fetching medicines:', error);
    throw error;
  }
};

// Add a new medicine
export const addMedicine = async (medicineData) => {
  try {
    const response = await axiosInstance.post('/backend/medicines', medicineData);
    return response.data;
  } catch (error) {
    console.error('Error adding medicine:', error);
    throw error;
  }
};

// Add a new prescription
export const addPrescription = async (prescriptionData) => {
  try {
    console.log('Sending prescription data:', prescriptionData);
    const response = await axiosInstance.post('/backend/prescriptions/add', prescriptionData);
    return response.data;
  } catch (error) {
    console.error('Error adding prescription:', error);
    throw error;
  }
};

// Fetch prescription for a specific patient by their ID
export const fetchPrescription = async (patientId) => {
  try {
    const response = await axiosInstance.get(`/backend/prescriptions/patient/${patientId}`);
    console.log('Fetching prescription:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching prescription:', error);
    throw error;
  }
};

// API for login
export const login = async (credentials) => {
  try {
    const response = await axiosInstance.post('/backend/auth/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

// Fetch total of patients
export const fetchTotalPatients = async () => {
  try {
    const response = await axiosInstance.get('/backend/patients/count/total');
    return response.data;
  } catch (error) {
    console.error('Error fetching total patients:', error);
    throw error;
  }
};

// Fetch total of new patients
export const fetchNewPatients = async () => {
  try {
    const response = await axiosInstance.get('/backend/patients/count/new');
    return response.data;
  } catch (error) {
    console.error('Error fetching new patients:', error);
    throw error;
  }
};

// Fetch total of medicines
export const fetchTotalMedicines = async () => {
  try {
    const response = await axiosInstance.get('/backend/medicines/count/total');
    return response.data;
  } catch (error) {
    console.error('Error fetching total medicines:', error);
    throw error;
  }
};
