import React from 'react';

const PrescriptionReceipt = React.forwardRef(({ patient, prescription }, ref) => {
  const calculateTotalQuantity = (dosage, duration) => {
    // This is a simplified calculation and may need to be adjusted based on actual dosage and duration format
    const dosagePerDay = dosage.split(' ').reduce((sum, part) => {
      const num = parseFloat(part);
      return isNaN(num) ? sum : sum + num;
    }, 0);
    const days = parseInt(duration.match(/\d+/)[0], 10);
    return dosagePerDay * days;
  };

  return (
    <div ref={ref} style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '20px auto', padding: '20px', border: '1px solid #ccc' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <p style={{ margin: '0', fontSize: '12px' }}>॥ श्री गणेशाय नमः ॥</p>
        <h1 style={{ color: '#4a90e2', margin: '5px 0', fontSize: '36px', fontWeight: 'bold' }}>OM CLINIC</h1>
        <p style={{ margin: '2px 0', fontSize: '14px' }}>Kuldeep Angan Nehru Nagar, Pimpri, Pune - 411 018.</p>
        <p style={{ margin: '2px 0', fontSize: '14px' }}>Time : Morning 10 am to 2 pm Evening 6 pm to 10 pm</p>
      </div>
      <div style={{ marginBottom: '10px', fontSize: '14px' }}>
        <p style={{ margin: '2px 0' }}>Dr. K. J. Mahajan (B.U.M.S.) Family Physician Reg. No. - I-42057</p>
        <p style={{ margin: '2px 0' }}>Mob.: 9552039495 Email: drkjmahajan@gmail.com</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontSize: '14px' }}>
        <p style={{ margin: '2px 0' }}>Name: <span style={{ borderBottom: '1px dotted #000' }}>{patient.name}</span></p>
        <p style={{ margin: '2px 0' }}>Date: <span style={{ borderBottom: '1px dotted #000' }}>{prescription.prescriptionDate}</span></p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontSize: '14px' }}>
        <p style={{ margin: '2px 0' }}>Age: <span style={{ borderBottom: '1px dotted #000' }}>{patient.age}</span></p>
        <p style={{ margin: '2px 0' }}>P: <span style={{ borderBottom: '1px dotted #000' }}>{patient.pulse}</span></p>
        <p style={{ margin: '2px 0' }}>BP: <span style={{ borderBottom: '1px dotted #000' }}>{patient.bloodPressure}</span></p>
        <p style={{ margin: '2px 0' }}>WT.: <span style={{ borderBottom: '1px dotted #000' }}>{patient.weight}</span></p>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>Rx</h2>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Medicine Name</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Dosage</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Duration</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {prescription.prescribedMedicines.map((medicine, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}) {medicine.medicineName}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{medicine.dosage}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{medicine.duration}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {calculateTotalQuantity(medicine.dosage, medicine.duration)} {medicine.unit}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {prescription.advice && (
          <div style={{ marginBottom: '10px' }}>
            <strong>Advice Given:</strong>
            <p>{prescription.advice}</p>
          </div>
        )}
        {prescription.followUpDate && (
          <div>
            <strong>Follow Up:</strong> {prescription.followUpDate}
          </div>
        )}
      </div>
      
    </div>
  );
});

PrescriptionReceipt.displayName = 'PrescriptionReceipt';

export default PrescriptionReceipt;