import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { BarChart, Users, Clipboard, Settings, Menu, ChevronDown, LogOut, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import './Dashboard.css';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';

export default function Dashboard({ children }) {
  const location = useLocation();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const SidebarNavLink = ({ to, icon, children }) => (
    <Nav.Link
      as={Link}
      to={to}
      className={`${location.pathname === to ? 'text-color fw-bold' : 'text-white'}`}
      style={{ fontFamily: 'Courier New' }}
      onClick={() => isMobile && setSidebarCollapsed(true)}
    >
      {icon}
      <span className={`ms-2 ${sidebarCollapsed && !isMobile ? 'd-none' : ''}`}>{children}</span>
    </Nav.Link>
  );

  const DropdownNavLink = ({ to, icon, children, isLogout }) => (
    <Link
      to={to}
      className="d-flex align-items-center text-decoration-none text-white border border-0"
      onClick={isLogout ? handleLogout : null}
      style={{ fontFamily: 'Times New Roman' }}
    >
      {icon}
      <span className="ms-2">{children}</span>
    </Link>
  );

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    window.location.href = '/';
  };

  const UserDropdown = ({ variant = 'light', className = '' }) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={variant} className={`shadow p-0 ${className}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            style={{ width: '24px', height: '30px' }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM12 14c-4.418 0-8 1.79-8 4v2h16v-2c0-2.21-3.582-4-8-4z"
            />
          </svg>
          <span className={`${sidebarCollapsed && !isMobile ? 'd-none' : ''}`} style={{fontFamily:'Palatino Linotype',fontSize:'12px'}}>Dr. John Doe</span>
          <ChevronDown className="w-4 h-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="rounded shadow p-2">
        <DropdownMenuItem className='dlink mt-2'>
          <DropdownNavLink to="/" 
            icon={<LogOut className="w-4 h-4 mr-2" />}
            isLogout
          >
            Logout
          </DropdownNavLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <Container fluid className="p-0 vh-100">
      <Row className="g-0 h-100">
        {/* Sidebar */}
        <Col 
          xs={12} 
          md={sidebarCollapsed ? 1 : 2} 
          lg={sidebarCollapsed ? 1 : 2} 
          xl={sidebarCollapsed ? 1 : 2} 
          className={`bg-dark text-white sidebar d-flex flex-column ${sidebarCollapsed && !isMobile ? 'collapsed' : ''} ${isMobile ? 'position-fixed' : ''}`} 
          style={{
            transition: 'all 0.3s', 
            height: '100vh', 
            overflowY: 'auto',
            zIndex: 1000,
            width: isMobile ? (sidebarCollapsed ? '0' : '100%') : 'auto',
            transform: isMobile ? (sidebarCollapsed ? 'translateX(-100%)' : 'translateX(0)') : 'none'
          }}
        >
          <div className="d-flex justify-content-between align-items-center p-3">
            {(!sidebarCollapsed || isMobile) && <h5 className="mb-0" style={{fontFamily: 'Engravers MT'}}>OM Clinic</h5>}
            <Button 
              variant="outline-light" 
              size="sm" 
              onClick={toggleSidebar}
              aria-label={sidebarCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
            >
              {isMobile ? <X size={18} /> : (sidebarCollapsed ? <ChevronRight size={18} /> : <ChevronLeft size={18} />)}
            </Button>
          </div>
          <Nav className="flex-column mt-3 flex-grow-1">
            <SidebarNavLink to="/dashboard" icon={<BarChart size={24} />}>Analytics</SidebarNavLink>
            <SidebarNavLink to="/patients" icon={<Users size={24} />}>Patients</SidebarNavLink>
            <SidebarNavLink to="/medicines" icon={<Clipboard size={24} />}>Medicines</SidebarNavLink>
            <SidebarNavLink to="/settings" icon={<Settings size={24} />}>Settings</SidebarNavLink>
          </Nav>
          <div className="mt-auto p-3">
            <UserDropdown variant="outline-light" className="w-100 justify-content-start" />
          </div>
        </Col>

        {/* Main Content Area */}
        <Col xs={12} md={sidebarCollapsed ? 11 : 10} lg={sidebarCollapsed ? 11 : 10} xl={sidebarCollapsed ? 11 : 10} className="h-100 d-flex flex-column">
          {/* Mobile Header */}
          <div className="d-md-none bg-dark text-white p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0" style={{fontFamily: 'Engravers MT'}}>OM Clinic</h5>
              <Button 
                variant="outline-light" 
                size="sm" 
                onClick={toggleSidebar}
                aria-label={sidebarCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
              >
                <Menu size={18} />
              </Button>
            </div>
          </div>
          
          {/* Main Content */}
          <Container fluid className="p-4 flex-grow-1 overflow-auto">
            {children}
          </Container>
        </Col>
      </Row>
    </Container>
  );
}