import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Dashboard from './components/admin/Dashboard';
import PatientManagement from './pages/PatientManagement';
import MedicineManagement from './pages/MedicineManagement';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import Signin from './components/Sign-in';
import PrivateRoute from './pages/PrivateRoute'; 

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Sign-in route */}
          <Route path="/" element={<Signin />} />
          
          {/* Private routes for authenticated users */}
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute>
                <Dashboard>
                  <Analytics />
                </Dashboard>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/patients" 
            element={
              <PrivateRoute>
                <Dashboard>
                  <PatientManagement />
                </Dashboard>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/medicines" 
            element={
              <PrivateRoute>
                <Dashboard>
                  <MedicineManagement />
                </Dashboard>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/settings" 
            element={
              <PrivateRoute>
                <Dashboard>
                  <Settings />
                </Dashboard>
              </PrivateRoute>
            } 
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
