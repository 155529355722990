import { Navigate } from "react-router-dom";

// A wrapper for authenticated routes
const PrivateRoute = ({ children }) => {
  const jwtToken = localStorage.getItem('jwtToken'); // Check for authentication token

  if (!jwtToken) {
    // If no token, redirect to login
    return <Navigate to="/" />;
  }

  // If authenticated, render the children (protected components)
  return children;
};

export default PrivateRoute;
