import { Pagination } from 'react-bootstrap'

const PaginationComponent = ({ currentPage, patientsPerPage, totalPatients, paginate }) => {
  const totalPages = Math.ceil(totalPatients / patientsPerPage)

  return (
    <Pagination className="mt-4 justify-content-end">
      <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />

      {[...Array(totalPages).keys()].map((page) => (
        <Pagination.Item
          key={page}
          active={page + 1 === currentPage}
          onClick={() => paginate(page + 1)}
        >
          {page + 1}
        </Pagination.Item>
      ))}

      <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={() => paginate(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  )
}

export default PaginationComponent
